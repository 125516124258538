<template>
	<div class="container">
		<Nav :curIndex="1"></Nav>
		<div class="account_right">
			<div class="title-box">
                <div class="title">Order Details</div>
                <div class="back" @click="back">
                    Back to order
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="step-box">
                <div class="step-title">Delivery Information</div>
                <el-steps class="pc-steps" :active="curStep" finish-status="finish" process-status="wait" align-center>
                    <el-step title="Submit Order" :description="detail.created_at"></el-step>
                    <el-step title="Payment Successful" :description="detail.pay_at"></el-step>
                    <el-step title="Shipped" :description="detail.express_at"></el-step>
                    <el-step title="Completed" :description="detail.complete_at"></el-step>
                </el-steps>
                <el-steps class="h5-steps" direction="vertical" :active="curStep" finish-status="finish" process-status="wait" align-center>
                    <el-step title="Submit Order" :description="detail.created_at"></el-step>
                    <el-step title="Payment Successful" :description="detail.pay_at"></el-step>
                    <el-step title="Shipped" :description="detail.express_at"></el-step>
                    <el-step title="Completed" :description="detail.complete_at"></el-step>
                </el-steps>
                <div v-if="curStep == 1" class="pay-btn" @click="pay">To Pay</div>
            </div>
            <div class="divide-line"></div>
            <div class="shipping-address">
                <div class="address-title">Shipping Address</div>
                <div class="address">
                	<div class="item1">{{shippingAddress.user_name_first}} {{shippingAddress.user_name_last}}</div>
                	<div class="item2">
                		{{shippingAddress.address}} {{shippingAddress.apartment}}
                		<br/>
                		({{shippingAddress.postal_code}})
                		<br/>
                		{{shippingAddress.city}}
                		<br/>
                		{{shippingAddress.state}}
                		<br/>
                		{{shippingAddress.country}}
                		<br/>
                		{{shippingAddress.phone}}
                	</div>
                </div>
            </div>
            <div class="divide-line"></div>
            <div class="payment-info">
                <div class="payment-title">Payment Information</div>
                <div class="goods-info">
                    <img class="img" :src="detail.picture" @click="toDetail(detail.good_id)" />
                    <div class="right">
                        <div class="info-item">
                            <div class="goods-desc">
                                <div class="name" @click="toDetail(detail.good_id)">{{detail.good_name}}</div>
                                <div class="tags">
                                    <el-tag v-for="(val,key) in JSON.parse(detail.sku)" :key="key" type="danger" size="mini" class="tag">{{val}}</el-tag>
                                </div>
                            </div>
                        </div>
                        <div class="info-item">
                            <div class="num">x{{detail.num}}</div>
                            <div class="price">{{$currencySymbol}}{{parseFloat(detail.per_price)}}</div>
                        </div>
                    </div>
                </div>
                <div class="other-info">
                    <div class="item">
                        <div class="label">Order number:</div>
                        <div class="value">
                            {{detail.order_no}}
                            <img class="copy" src="@/assets/images/index/copy.png" @click="copy(detail.order_no)" />
                        </div>
                    </div>
                    <div v-if="detail.status == 2 && detail.express_no" class="item">
                        <div class="label">Express number:</div>
                        <div class="value">
                            {{detail.express_no}}
                            <img class="copy" src="@/assets/images/index/copy.png" @click="copy(detail.express_no)" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">Subtotal:</div>
                        <div class="value">{{$currencySymbol}}{{parseFloat(detail.total_price)}}</div>
                    </div>
                    <div class="item">
                        <div class="label">Coupon savings:</div>
                        <div class="value">{{$currencySymbol}}0</div>
                    </div>
                    <div class="item">
                        <div class="label">Shipping charges:</div>
                        <div class="value">{{$currencySymbol}}0</div>
                    </div>
                    <div class="item">
                        <div class="label">Actual payment amount:</div>
                        <div class="value" style="font-size: 2.2rem;">{{$currencySymbol}}{{parseFloat(detail.total_price)}}</div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
                order_id: '',
                detail: {
                    sku: '{}'
                },
                shippingAddress: {}
            }
        },
        created() {
            this.order_id = this.$route.query.id
            this.getDetail()
        },
        computed: {
            curOrderId() {
                return this.$route.query.id
            },
            
            curStep() {
                let active = 0
                let status = this.detail.status
                
                switch(status) {
                    case 0:
                        active = 1
                        break
                    case 1:
                        active = 2
                        break
                    case 2:
                        active = 3
                        break
                    case 3:
                        active = 4
                        break
                }
                
                return active
            }
        },
        watch: {
            curOrderId(val) {
                // console.log(val)
                this.order_id = val
                this.getDetail()
            }
        },
        methods: {
            back() {
                this.$router.go(-1)
            },
            
            getDetail() {
                this.$api.orderDetail({
                    id: this.order_id
                },true).then(res => {
                    this.detail = res.data
                    this.shippingAddress = JSON.parse(this.detail.address)
                })
            },
            
            pay() {
                this.$api.payOrder({
                    order_id: this.order_id
                },true).then(res => {
                    location.href = res.data
                })
            },
            
            copy(text) {
                let input = document.createElement("input")
                input.value = text
                document.body.appendChild(input)
                input.select()
                input.setSelectionRange(0, input.value.length)
                document.execCommand('Copy')
                document.body.removeChild(input)
                this.$message({
                    message: 'Copy successfully',
                    type: 'success',
                    duration: 1500
                })
            },
            
            toDetail(id) {
                this.$router.push({
                    path: '/detail',
                    query: {
                        id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
                .title-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 3rem;
                    
                    .title {
                        margin-right: 3rem;
                        font-size: 2rem;
                        color: #333333;
                        white-space: nowrap;
                    }
                    
                    .back {
                        max-width: 100%;
                        font-size: 1.4rem;
                        color: #333333;
                        word-wrap: break-word;
                        cursor: pointer;
                    }
                }
                
                .step-box {
                    width: 100%;
                    
                    .step-title {
                        width: 100%;
                        box-sizing: border-box;
                        padding-left: 3rem;
                        margin-bottom: 2.5rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .h5-steps {
                        display: none;
                    }
                    
                    .pay-btn {
                        display: inline-block;
                        min-width: 6rem;
                        height: 4.5rem;
                        line-height: 4.5rem;
                        box-sizing: border-box;
                        padding: 0 5rem;
                        margin: 2.5rem 0 0 3rem;
                        border-radius: 2px;
                        font-size: 1.5rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #e6a23c;
                        cursor: pointer;
                    }
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 3rem 0;
                    background-color: #e9e9e9;
                }
                
                .shipping-address {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 3rem;
                    
                    .address-title {
                        width: 100%;
                        margin-bottom: 2.5rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .address {
                    	width: 100%;
                    	
                    	.item1 {
                    		width: 100%;
                    		margin-bottom: 1.5rem;
                    		font-size: 1.6rem;
                    		font-weight: bold;
                    		color: #333333;
                    		word-break: break-all;
                    	}
                    	
                    	.item2 {
                    		width: 100%;
                    		line-height: 160%;
                    		font-size: 1.4rem;
                    		color: #333333;
                    		word-break: break-all;
                    	}
                    }
                }
                
                .payment-info {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 3rem;
                    
                    .payment-title {
                        width: 100%;
                        margin-bottom: 2.5rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .goods-info {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 50rem;
                        margin-bottom: 1.5rem;
                        
                        .img {
                            min-width: 9rem;
                            max-width: 9rem;
                            height: 9rem;
                            margin-right: 1.5rem;
                            cursor: pointer;
                        }
                        
                        .right {
                            width: 100%;
                            
                            .info-item {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                
                                &:not(:last-child) {
                                    margin-bottom: 1.5rem;
                                }
                                
                                .goods-desc {
                                    width: 100%;
                                    
                                    .name {
                                        width: 100%;
                                        margin-bottom: .5rem;
                                        font-size: 1.4rem;
                                        color: #333333;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        cursor: pointer;
                                    }
                                    
                                    .tags {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        
                                        .tag {
                                            &:not(:last-child) {
                                                margin-right: .5rem;
                                            }
                                        }
                                    }
                                }
                                
                                .num {
                                    margin-right: 3rem;
                                    font-size: 1.5rem;
                                    color: #000000;
                                    white-space: nowrap;
                                }
                                
                                .price {
                                    max-width: 100%;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                    
                    .other-info {
                        width: 100%;
                        
                        .item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            max-width: 50rem;
                            
                            &:not(:last-child) {
                                margin-bottom: 1.5rem;
                            }
                            
                            .label {
                                max-width: 48%;
                                font-size: 1.4rem;
                                color: #333333;
                                word-wrap: break-word;
                            }
                            
                            .value {
                                display: flex;
                                align-items: center;
                                max-width: 48%;
                                font-size: 1.5rem;
                                font-weight: bold;
                                color: #000000;
                                word-break: break-all;
                                
                                .copy {
                                    min-width: 2rem;
                                    max-width: 2rem;
                                    height: 2rem;
                                    margin-left: .4rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
                .title-box {
                    display: flex;
                    flex-direction: column-reverse;
                    width: 100%;
                    margin-bottom: 2.5rem;
                    
                    .title {
                        width: 100%;
                        font-size: 2rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .back {
                        width: 100%;
                        margin-bottom: 2.5rem;
                        font-size: 1.4rem;
                        color: #333333;
                        word-wrap: break-word;
                        cursor: pointer;
                    }
                }
                
                .step-box {
                    width: 100%;
                    
                    .step-title {
                        width: 100%;
                        box-sizing: border-box;
                        padding-left: 1rem;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .pc-steps {
                        display: none;
                    }
                    
                    .h5-steps {
                        height: 24rem;
                    }
                    
                    .pay-btn {
                        display: inline-block;
                        max-width: 100%;
                        height: 4.2rem;
                        line-height: 4.2rem;
                        box-sizing: border-box;
                        padding: 0 2.5rem;
                        margin: 2rem 0 0 1rem;
                        border-radius: 2px;
                        font-size: 1.4rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #e6a23c;
                        cursor: pointer;
                    }
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 2.5rem 0;
                    background-color: #e9e9e9;
                }
                
                .shipping-address {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 1rem;
                    
                    .address-title {
                        width: 100%;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .address {
                    	width: 100%;
                    	
                    	.item1 {
                    		width: 100%;
                    		margin-bottom: 1.2rem;
                    		font-size: 1.6rem;
                    		font-weight: bold;
                    		color: #333333;
                    		word-break: break-all;
                    	}
                    	
                    	.item2 {
                    		width: 100%;
                    		line-height: 150%;
                    		font-size: 1.4rem;
                    		color: #333333;
                    		word-break: break-all;
                    	}
                    }
                }
                
                .payment-info {
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 1rem;
                    
                    .payment-title {
                        width: 100%;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .goods-info {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 1.2rem;
                        
                        .img {
                            min-width: 8rem;
                            max-width: 8rem;
                            height: 8rem;
                            margin-right: 1rem;
                            cursor: pointer;
                        }
                        
                        .right {
                            width: 100%;
                            
                            .info-item {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                
                                &:not(:last-child) {
                                    margin-bottom: 1rem;
                                }
                                
                                .goods-desc {
                                    width: 100%;
                                    
                                    .name {
                                        width: 100%;
                                        margin-bottom: .4rem;
                                        font-size: 1.4rem;
                                        color: #333333;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        cursor: pointer;
                                    }
                                    
                                    .tags {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        
                                        .tag {
                                            &:not(:last-child) {
                                                margin-right: .4rem;
                                            }
                                        }
                                    }
                                }
                                
                                .num {
                                    margin-right: 2.5rem;
                                    font-size: 1.5rem;
                                    color: #000000;
                                    white-space: nowrap;
                                }
                                
                                .price {
                                    max-width: 100%;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                    
                    .other-info {
                        width: 100%;
                        
                        .item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            
                            &:not(:last-child) {
                                margin-bottom: 1.2rem;
                            }
                            
                            .label {
                                max-width: 48%;
                                font-size: 1.4rem;
                                color: #333333;
                                word-wrap: break-word;
                            }
                            
                            .value {
                                display: flex;
                                align-items: center;
                                max-width: 48%;
                                font-size: 1.4rem;
                                font-weight: bold;
                                color: #000000;
                                word-break: break-all;
                                
                                .copy {
                                    min-width: 2rem;
                                    max-width: 2rem;
                                    height: 2rem;
                                    margin-left: .4rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
        	}
        }
    }
</style>